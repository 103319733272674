// export const BASE_URL = 'https://nsfukfarmscapoc.azurewebsites.net/shp/';
export const BASE_URL = 'https://farms-caeu-test-service.nsf.org/shp/';
export const production = false;

export const SessionData = {
    get token(): string {
        // return "v+d+KTTuVyhnbV9do+KDdOgVNQF7534R7ZeEE7HJsfVB82SJOYvTRP6MsOXIM3s="
        return sessionStorage.getItem('token')
    },
    set token(value: string) {
        sessionStorage.setItem('token', value)
    },

    get formDigest(): string {
        // return "ShB3U240Qyr3wH4Z"
        return sessionStorage.getItem('form-digest');
    },
    set formDigest(value: string) {
        sessionStorage.setItem('form-digest', value)
    },

    get clientId() {
        // return 'developer'
        return sessionStorage.getItem('client-id')
    },
    set clientId(value: string) {
        sessionStorage.setItem('client-id', value)
    },

    get date() {
        // return '27-oct-2023'
        const date = sessionStorage.getItem('date')
        if (!date) {
            return '';
        }
        return `${date.substring(6, 8)}-${date.substring(4, 6)}-${date.substring(0, 4)}`;
    },
    set date(value: string) {
        sessionStorage.setItem('date', value)
    },

    get ncList() {
        return sessionStorage.getItem('nc-list')
    },
    set ncList(value: string) {
        sessionStorage.setItem('nc-list', value)
    },
    get pin() {
        return sessionStorage.getItem('pin')
    },
    set pin(value: string) {
        sessionStorage.setItem('pin', value)
    },
}
