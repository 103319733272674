import { Component, OnInit, ViewChild } from '@angular/core';
import { FileUploadDto, SelectedFileDto } from 'src/app/dto/file-upload.dto';
import { CheckBoxItem } from 'src/app/form-controls/select/checkbox-options.type';
import { BackEndDataService } from 'src/app/services/backend-data.service';
import { SessionData } from 'src/environments/environment';
import { v4 } from 'uuid';
import { lastValueFrom } from 'rxjs';
import { SelectControl } from 'src/app/form-controls/select/select.control';
import { LoaderService } from 'src/app/services/loader.service';
import { AlertService, ConfirmBoxResult } from 'src/app/services/alert/alert.service';
import { PlatformService } from 'src/app/services/platform.service';

export interface SelectedFile {
  name: string;
  size: number;
  file: File;
  uuid: string;
}

export interface UploadFile {
  ncName: string;
  ncValue: string;
  filename: string;
  fileUuid: string;
  file: File;
  err?: string
}

@Component({
  templateUrl: './upload-evidence.page.html',
  styleUrls: ['./upload-evidence.page.scss']
})
export class UploadEvidencePage implements OnInit {
  selectedFiles: SelectedFile[] | null = null

  ncList: CheckBoxItem[] = [];

  filesToUpload: UploadFile[] | null = null;
  selectedNcItems: CheckBoxItem[] | null = null;

  @ViewChild('selectControl') selectControlRef: SelectControl;

  auditDate = SessionData.date;

  dragging: boolean = false;

  failedProcessFiles: Map<string, { fileName: string, reason: string }> = new Map();
  /** variable to detect small devices */
  public isSmallScreenDevice: boolean = false;

  ngOnInit(): void {
    const ncList = SessionData.ncList;
    if (ncList) {
      this.ncList = ncList.split(',').sort().map((item) => ({ text: item.split('|')[0], value: item, checked: false }));
    }
    if (this._platformSvc.ANDROID === true || this._platformSvc.IOS === true) {
      this.isSmallScreenDevice = true;
    }
  }

  constructor(
    private readonly backEndSvc: BackEndDataService,
    private readonly loaderSvc: LoaderService,
    private readonly alertSvc: AlertService,
    private readonly _platformSvc: PlatformService
  ) {

  }


  /**
   * Filter out the files which are not in allowed extensions
   * @param files Selected files from file system
   * @returns returns an array of allowed files
   */
  async #filterInvalidFiles(files: File[]): Promise<File[]> {
    const invalidFilesName: string[] = []
    const allowedExtensions = new Set(['jpg', 'gif', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'png', 'pdf']);
    const validFiles: File[] = files.filter(file => {
      const extension = file.name.split('.').pop()?.toLowerCase();
      if (!allowedExtensions.has(extension)) {
        invalidFilesName.push(file.name);
        return false;
      }
      return true;
    })

    if (invalidFilesName.length) {
      let alertMsg = 'Below files are invalid. ';
      await this.alertSvc.show(alertMsg, 'Invalid files', undefined, invalidFilesName);
    }
    return validFiles;
  }

  async onFilesChange(e: any) {
    const target = e.target as HTMLInputElement;
    const files: FileList = target.files;
    if (!(files.length > 0)) {
      return;
    }
    const validFiles = await this.#filterInvalidFiles(Array.from(files));
    if (!validFiles.length) {
      return;
    }
    this.#processNewFile(validFiles);
    target.files = null;
    target.value = null;
  }

  async #processNewFile(files: File[]): Promise<void> {
    const newFiles: SelectedFile[] = [];
    for await (let file of files) {
      let existingFileIndex: number | null = null;
      if (this.selectedFiles) {
        const fileExists = this.selectedFiles.find((_file: SelectedFile, index: number) => {
          existingFileIndex = index;
          return _file.name === file.name;
        });
        if (fileExists) {
          const result: ConfirmBoxResult = await this.alertSvc.confirm('Confirm', `Filename "${file.name}" is already exists. Do you want to replace it?`, 'Yes', 'No');
          if (result == ConfirmBoxResult.Yes) {
            this.selectedFiles.splice(existingFileIndex, 1);
          } else {
            continue;
          }
        }
        existingFileIndex = null;
      }
      const uuid = v4();
      const _file: SelectedFile = { name: file.name, size: Math.round(file.size / 1024), file, uuid }
      newFiles.push(_file);
    }
    if (this.selectedFiles) {
      this.selectedFiles.push(...newFiles);
    } else {
      this.selectedFiles = newFiles;
    }
  }

  removeSelectedFile(selectedIndex: number) {
    if (!this.selectedFiles[selectedIndex]) {
      return;
    }
    this.selectedFiles.splice(selectedIndex, 1);
    if (!this.selectedFiles.length) {
      this.selectControlRef.toggleSelectAll(false, true);
    }
  }

  onNcSelectionChange(selectedNcItems: CheckBoxItem[]) {
    this.selectedNcItems = selectedNcItems
  }


  async onAddToListClick() {

    if (!this.selectedNcItems.length) {
      return;
    }
    const itemsToUpload: UploadFile[] = [];

    for await (let ncItem of this.selectedNcItems) {
      for (let selectedFile of this.selectedFiles) {
        let existingFileIndex: number | null = 0
        if (this.filesToUpload) {


          const fileExists = this.filesToUpload.find((item: UploadFile, index: number) => {
            const itemMatched = item.filename === selectedFile.name && item.ncValue === ncItem.value;
            if (itemMatched) {
              existingFileIndex = index;
            }
            return itemMatched;
          })
          if (fileExists) {
            const result: ConfirmBoxResult = await this.alertSvc.confirm('Confirm', `Filename "${fileExists.filename}" with evidence "${fileExists.ncValue}" is already exists. Do you want to replace it?`, 'Yes', 'No');
            if (result == ConfirmBoxResult.Yes) {
              const fileReplaceResult = this.filesToUpload.splice(existingFileIndex, 1);
            } else {
              continue;
            }
          }
          existingFileIndex = null;
        }
        const item: UploadFile = {
          file: selectedFile.file,
          filename: selectedFile.name,
          ncValue: ncItem.value,
          ncName: ncItem.text,
          fileUuid: selectedFile.uuid
        };
        itemsToUpload.push(item);
      }
    }

    if (Array.isArray(this.filesToUpload) && this.filesToUpload.length) {
      this.filesToUpload.push(...itemsToUpload);
    } else {
      this.filesToUpload = itemsToUpload;
    }
    this.selectedFiles = null;
    this.selectControlRef.toggleSelectAll(false, false);
    this.selectedNcItems = null;

  }


  async #getBase64(file: File): Promise<string | null> {
    return new Promise((res, rej) => {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const result = reader.result as string;
        res(result.split(';base64,')[1]);
        // res(result);
      };
      reader.onerror = (error) => {
        let errMsg = 'Unable to process file : ' + file.name
        if (error instanceof ProgressEvent) {
          errMsg = file.name + '. ' + error?.target?.error?.message || 'Unable to process file : ' + file.name;
        }

        this.failedProcessFiles.set(file.name, {
          fileName: file.name,
          reason: errMsg
        })
        const files = this.filesToUpload.filter(item => item.filename === file.name)

        if (files?.length) {
          files.forEach((file) => {
            file.err = errMsg
          })
        }
        res(null);
      };
    })
  }

  async #createPayLoad(): Promise<FileUploadDto> {
    this.failedProcessFiles.clear()

    const uniqueFiles: Map<string, SelectedFileDto> = new Map();

    for await (const file of this.filesToUpload) {
      if (uniqueFiles.has(file.fileUuid)) {
        const _file = uniqueFiles.get(file.fileUuid);
        _file.evidences.push(file.ncValue);
        continue;
      }
      const fileBase64 = await this.#getBase64(file.file);
      if (!fileBase64) {
        continue;
      }
      const _file: SelectedFileDto = {
        evidences: [file.ncValue],
        file: fileBase64,
        fileName: file.filename
      }
      uniqueFiles.set(file.fileUuid, _file)
    }

    const payload: FileUploadDto = {
      customerId: SessionData.clientId,
      date: SessionData.date,
      files: Array.from(uniqueFiles.values()),
      formDigestValue: SessionData.formDigest,
      token: SessionData.token,
      uniqueNumber: SessionData.pin
    }

    return payload;
  }

  onDragStart(e: any) {
    e.preventDefault();
  }

  onDragOver(e: any) {
    e.preventDefault();
    this.dragging = true
  }

  onDragLeave() {
    this.dragging = false;
  }

  async onDrop(ev: any) {

    this.dragging = false;
    ev.preventDefault();
    const dataTransfer = ev.dataTransfer as DataTransfer;
    let files: File[] = [];
    if (ev.dataTransfer.items) {
      files = Array.from(dataTransfer.items).filter(item => item.kind === 'file').map(item => item.getAsFile());
    } else {
      files = Array.from(dataTransfer.files);
    }

    const validFiles = await this.#filterInvalidFiles(files);
    if(!validFiles.length) {
      return;
    }

    this.#processNewFile(validFiles);

  }


  #resetData() {
    this.selectedFiles = this.filesToUpload = this.selectedNcItems = null;
    this.failedProcessFiles.clear();
    this.selectControlRef.toggleSelectAll(false);
  }

  async onSubmitClick() {
    this.loaderSvc.show();
    const payload: FileUploadDto = await this.#createPayLoad();

    if (this.failedProcessFiles.size) {
      this.loaderSvc.hide();
      return;
    }


    const confirmResult: ConfirmBoxResult = await this.alertSvc.confirm('Confirm', 'Upload evidence ?',);

    if (confirmResult !== ConfirmBoxResult.Yes) {
      this.loaderSvc.hide();

      return;
    }



    this.loaderSvc.show();
    try {
      await lastValueFrom(this.backEndSvc.uploadFiles(payload));
      this.alertSvc.show('Files uploaded successfully.');

      this.#resetData();
    } catch (err) {
      this.alertSvc.show(err.message)
    } finally {
      this.loaderSvc.hide()
    }
  }

  onRemoveUploadFileClick(index: number) {
    if (!(this.filesToUpload[index])) {
      return;
    }
    this.filesToUpload.splice(index, 1);
  }


  onWarningBtnClick(errMsg: string) {
    this.alertSvc.show(errMsg);
  }
}
