<section class="progress-control__wrapper">
        <span class="title">{{title}}</span>
        <button class="cancel-btn" title="cancel-upload" type="button">
            <img src="assets/images/close.png" alt="remove icon">
        </button>
        <section class="bar">
            <div [style.width.%]="_value" class="inner-bar"></div>
        </section>
        <span class="current-progress">48% done...</span>
        <span class="file-size">{{fileSize}} kb</span>
</section>