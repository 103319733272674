import { Injectable } from "@angular/core";
import { BehaviorSubject } from 'rxjs'

export type LoaderState = {
    visibility: boolean;
}

@Injectable()
export class LoaderService {
    loaderState$: BehaviorSubject<LoaderState> = new BehaviorSubject<LoaderState>({
        visibility: false
    })

    hide() {
        this.loaderState$.next({ visibility: false })
    }
    show() {
        this.loaderState$.next({ visibility: true })
    }

}