<div class="page-container">
    <section class="tool-bar">
        <span class="app-title">Farm UI</span>
    </section>
    <main class="main-content">
        <router-outlet></router-outlet>
    </main>

    <footer class="page-footer">
        <span>(C) Copyright 2023, NSF International. All rights reserved.</span>
    </footer>
</div>
<dialog #loader class="dialog">
    <section class="loader_inner">
        <span class="loader"></span>
        <span class="loader-text">Please wait...</span>
    </section>
</dialog>