<section class="page-container">
    <img src="assets/images/logo.png" class="logo" alt="logo">
    <h2 class="page-title">Upload Evidence for your audit date {{auditDate}}</h2>
    <main class="page-main-content">
        <section class="sec-one__wrapper">
            <section class="inner-border__sec">
                <div (drop)="onDrop($event)" (dragleave)="onDragLeave()" [ngClass]="{'drag' : dragging}"
                    (dragover)="onDragOver($event)" (dragstart)="onDragStart($event)" class="upload__sec"
                    draggable="true">
                    <div *ngIf="isSmallScreenDevice === false">
                        <img src="assets/images/upload.png" alt="upload icon">
                        <span>Drag and drop files here</span>
                        <span>or</span>
                    </div>
                    <label class="app-btn">
                        <input (change)="onFilesChange($event)" multiple
                            accept=".jpg, .gif, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .png,.pdf" type="file" /> Browse Files
                    </label>
                </div>

                <div class="files-upload__wrapper">
                    <!-- <div class="no-file-selected__wrapper">
                    <span>Currently there are no files selected. Please select files to upload.</span>
                </div> -->
                    <!-- <section class="progress-bar__wrapper">
                    <app-progress-bar title="photo.jpg" [value]="40" [fileSize]="60"></app-progress-bar>
                    <app-progress-bar title="files.jpg" [value]="20" [fileSize]="30"></app-progress-bar>
                    <app-progress-bar title="system.xlms" [value]="70" [fileSize]="30"> </app-progress-bar>
                </section> -->
                    <section *ngIf="selectedFiles?.length; else noFiles" class="uploaded-files-list">
                        <p>Uploaded Files</p>
                        <div class="list-container">
                            <ng-container *ngFor="let item of selectedFiles;index as i">
                                <span class="file-name">{{item.name}}</span> <span class="fixe-size">{{item.size}}
                                    kb</span>
                                <button (click)="removeSelectedFile(i)" type="button" class="icon-btn">
                                    <img src="assets/images/close.png" alt="remove icon"></button>
                            </ng-container>
                        </div>
                    </section>
                    <ng-template #noFiles>
                        <section class="no-files">
                            <p>Currently there are no files selected. Please select files to upload.</p>
                        </section>
                    </ng-template>

                </div>
            </section>
            <section class="note-sec__wrapper">
                <strong>Note:</strong> Files with extension as JPG, PDF, GIF, DOC, DOCX, XLS, XLSX, PPT, PPTX, PNG can only
                be
                attached
            </section>
            <section class="list-sec__wrap">
                <b>This evidence relates to</b>
                <section class="selected-dropdown__wrapper">
                    <app-select [disabled]="!selectedFiles?.length" #selectControl
                        (change)="onNcSelectionChange($event)" [options]="ncList"></app-select>
                </section>
                <button (click)="onAddToListClick()" [disabled]="!(selectedNcItems?.length)" class="app-btn">Add To
                    List</button>
            </section>
        </section>
        <section class="sec_two_wrapper">
            <b>Files to be uploaded</b>
            <section class="uploaded-files-table">
                <div class="table table-header">
                    <b>NC Ref</b>
                    <b>Filename</b>
                    <b class="action-lbl">Action</b>
                </div>
                <div *ngIf="filesToUpload?.length; else noUploadFile" class="table table-body">
                    <ng-container *ngFor="let item of filesToUpload;index as i">
                        <span>{{item.ncName}}</span>
                        <span class="file-name">{{item.filename}}</span>
                        <button *ngIf="item.err" (click)="onWarningBtnClick(item.err)" title="error file" class="warning-btn icon-btn">
                            <img src="assets/images/warning.png" alt="remove icon" title="{{item.err}}" />
                        </button>
                        <button (click)="onRemoveUploadFileClick(i)" title="remove file" class="delete-btn icon-btn">
                            <img src="assets/images/delete.svg" alt="remove icon" />
                        </button>
                    </ng-container>
                </div>
                <ng-template #noUploadFile>
                    <section class="no-upload">
                        <img src="assets/images/no-upload-file.svg" alt="no upload items image">
                        <p>Currently there are no files uploaded. Please select files to upload.</p>
                    </section>
                </ng-template>
            </section>

            <div class="btn-right">
                <button [disabled]="!(filesToUpload?.length)" (click)="onSubmitClick()" class="app-btn"
                    type="button">Submit</button>
            </div>
        </section>
    </main>
</section>