import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';
import { CheckBoxItem } from './checkbox-options.type';



@Component({
  selector: 'app-select',
  templateUrl: './select.control.html',
  styleUrls: ['./select.control.scss']
})
export class SelectControl {

  isOpen: boolean = false;

  @Input()
  options: CheckBoxItem[] = [];

  @Input()
  disabled: boolean = false;

  @Output()
  change: EventEmitter<CheckBoxItem[]> = new EventEmitter<CheckBoxItem[]>();


  @ViewChild('popoverWrapper') popoverElementRef: ElementRef<HTMLElement>;

  constructor(
    private eRef: ElementRef
  ) {

  }
  trackByCheckList(index: number, item: CheckBoxItem) {
    return item.text;
  }
  trackByCheckedCheckList(index: number, item: CheckBoxItem) {
    return item.text;
  }
  toggleSelectAll(checkAll: boolean, emitChangeEvent: boolean = true) {
    if (this.disabled) {
      return;
    }
    this.options.forEach(item => item.checked = checkAll)
    if (emitChangeEvent) {
      this.change.emit(this.#getCheckedItems());
    }
  }

  onUnCheckAllClick(e: Event) {
    if (this.disabled) {
      return;
    }
    e.stopPropagation();
    e.preventDefault()
    this.toggleSelectAll(false);
  }



  #getCheckedItems() {
    const checkedItems = this.options.filter(item => item.checked);
    return checkedItems;
  }

  onCheckboxChange(item: CheckBoxItem) {
    if (this.disabled) {
      return;
    }
    item.checked = !item.checked;

    this.change.emit(this.#getCheckedItems());
  }

  uncheckSingleCheckBox(item: CheckBoxItem) {
    item.checked = false;
    this.change.emit(this.#getCheckedItems());
  }

  @HostListener('document:click', ['$event'])
  handleDocumentClick(event: KeyboardEvent) {
    const target = event.target as Node;
    const popover = this.popoverElementRef?.nativeElement;
    if (this.eRef.nativeElement.contains(event.target) || (popover && popover.contains(target)) ) {
      // inside component clicked
      if (popover.contains(target)) {
        // inside popover clicked
        this.isOpen = true;
      } 
      
    } else {
      // outside component popover clicked
      this.isOpen = false;
    }
      
  }
}
