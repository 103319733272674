import { Injectable } from "@angular/core";
import { VerifyPinDto } from "../dto/verify-pin.dto";
import { HttpService } from "./http.service";
import { FileUploadDto } from "../dto/file-upload.dto";

@Injectable()
export class BackEndDataService {

    constructor(
        private readonly httpSvc: HttpService
    ) {

    }

    verifyPin(payload: VerifyPinDto) {
        return this.httpSvc.post<true>({
            endPoint: 'request/verify',
            body: payload
        })
    }

    uploadFiles(payload: FileUploadDto) {
        return this.httpSvc.post(
            {
                endPoint: 'upload',
                body: payload
            })
    }
}