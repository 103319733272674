import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.control.html',
  styleUrls: ['./progress-bar.control.scss']
})
export class ProgressBarControl {




  @Input()
  title: string = 'Title'

  /** Value in percent */
  _value: number = 50;

  @Input()
  set value(newValue: number) {
    if ((newValue <= this.max) && (newValue >= this._min)) {
      const range = this.max - this._min
      const currentProgress = newValue - this._min;
      this._value = (currentProgress/100) * range;
    }
  }

  @Input()
  max: number = 100

  _min: number = 0;

  @Input()
  set min(value: number) {
    if (value >= 0) {
      this._min = value;
    }
  }

  @Input()
  progressText: number = 100

  @Input()
  fileSize: number = 0


}
