<section class="select-wrapper">
    <section class="inner-sec" [ngClass]="{'control-disabled': disabled}" cdkOverlayOrigin #trigger="cdkOverlayOrigin">
        <ng-container *ngFor="let item of options; trackBy:trackByCheckedCheckList">
            <span *ngIf="item.checked" class="selected-item">
                <span class="text-item">
                    {{item.text}}
                </span>
                <button [disabled]="disabled" (click)="uncheckSingleCheckBox(item)" class="icon-btn remove-btn"
                    title="remove item" type="button" role="button">
                    <img src="assets/images/close.png" alt="remove icon">
                </button>
            </span>
        </ng-container>
        <span class=" separator"></span>
        <button [disabled]="disabled" (click)="onUnCheckAllClick($event)" class="icon-btn remove-all-btn"
            title="remove all items" type="button" role="button">
            <img src="assets/images/close.png" alt="remove icon">
        </button>
        <button [disabled]="disabled" (click)="isOpen = !isOpen" class="icon-btn show-items-btn" title="show items"
            type="button" role="button">
            <img src="assets/images/downarrow.png" alt="open dropdown icon">
        </button>
    </section>

    <ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="trigger" [cdkConnectedOverlayOpen]="isOpen">
        <section #popoverWrapper class="popover__wrapper">

            <div class="select-all">
                <button (click)="onUnCheckAllClick($event)" class="icon-btn remove-all-btn" title="remove all items"
                    type="button" role="button">
                    <img src="assets/images/close.png" alt="remove icon">
                </button>
                <button [disabled]="disabled" (click)="isOpen = !isOpen" class="icon-btn show-items-btn"
                    title="show items" type="button" role="button">
                    <img class="close-up" src="assets/images/downarrow.png" alt="close icon">
                </button>
                <span class="separator"></span>
                <label (click)="toggleSelectAll(true)">Select All</label>
            </div>
            <section class="checkbox-list">
                <label class="checkbox-item" *ngFor="let item of options; trackBy:trackByCheckList"
                    [ngClass]="{ 'checked' :  item.checked}">
                    <input type="checkbox" (change)="onCheckboxChange(item)" [checked]="item.checked" />
                    <span>{{item.text}}</span>
                </label>
            </section>
        </section>
    </ng-template>
</section>