import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TwoFactorAuthComponent } from './pages/two-factor-auth/two-factor-auth.component';
import { UploadEvidencePage } from './pages/upload-evidence/upload-evidence.page';
import { NotFoundPage } from './pages/not-found/not-found.page';
import { ProgressBarControl } from './form-controls/progress-bar/progress-bar.control';
import { SelectControl } from './form-controls/select/select.control';
import { OverlayModule } from '@angular/cdk/overlay';
import { HttpService } from './services/http.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BackEndDataService } from './services/backend-data.service';
import { LoaderService } from './services/loader.service';
import { AlertService } from './services/alert/alert.service';
import { DialogModule } from '@angular/cdk/dialog';
import { AlertComponent } from './components/alert.component';
import { ConfirmComponent } from './components/confirm.component';
import { PlatformService } from './services/platform.service';

@NgModule({
  declarations: [
    AppComponent,
    TwoFactorAuthComponent,
    UploadEvidencePage,
    NotFoundPage,
    ProgressBarControl,
    SelectControl,
    AlertComponent,
    ConfirmComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    OverlayModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    DialogModule,
  ],
  providers: [HttpService, BackEndDataService, LoaderService, AlertService, PlatformService
   
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
