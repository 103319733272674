import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TwoFactorAuthComponent } from './pages/two-factor-auth/two-factor-auth.component';
import { NotFoundPage } from './pages/not-found/not-found.page';
import { UploadEvidencePage } from './pages/upload-evidence/upload-evidence.page';
import { AuthGuard } from './auth/auth.guard';

const routes: Routes = [
  {
    path: '',
    component: TwoFactorAuthComponent
  },
  {
    path: 'upload-evidence',
    component: UploadEvidencePage,
    canActivate: [AuthGuard]
  },
  {
    path: '',
    redirectTo: '',
    pathMatch: 'full'
  },
  {
    path: '**',
    component: NotFoundPage
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
