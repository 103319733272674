<main class="main-page">

  <img src="assets/images/logo.png" class="logo" alt="logo">

  <h2 class="page-title">Upload Evidence for your audit date {{date}}</h2>
  <form [formGroup]="pinForm" class="main-block" (ngSubmit)="onSubmit()">

    <img alt="auth icon" class="img" src="assets/images/2FA_Icon.png">

    <span class="title">Two-Factor Authentication</span>

    <span class="sub-title">Enter the six-digit code sent in the separate email.</span>

    <input inputmode="numeric" maxlength="1" autocomplete="nope" #input1 formControlName="pin1" (input)="onInput($event, input2)" title="enter PIN 1"
      type="text" name="auth-key" class="pin1 pin" min="0" max="9">
    <input inputmode="numeric" maxlength="1" autocomplete="nope" #input2 formControlName="pin2" (input)="onInput($event, input3, input1)" title="enter PIN 2"
      type="text" name="auth-key" class="pin2 pin" min="0" max="9">
    <input inputmode="numeric" maxlength="1" autocomplete="nope" #input3 formControlName="pin3" (input)="onInput($event, input4, input2)" title="enter PIN 3"
      type="text" name="auth-key" class="pin3 pin" min="0" max="9">
    <input inputmode="numeric" maxlength="1" autocomplete="nope" #input4 formControlName="pin4" (input)="onInput($event, input5, input3)" title="enter PIN 4"
      type="text" name="auth-key" class="pin4 pin" min="0" max="9">
    <input inputmode="numeric" maxlength="1" autocomplete="nope" #input5 formControlName="pin5" (input)="onInput($event, input6, input4)" title="enter PIN 5"
      type="text" name="auth-key" class="pin5 pin" min="0" max="9">
    <input inputmode="numeric" maxlength="1" autocomplete="nope" #input6 formControlName="pin6" (input)="onInput($event, undefined, input5)" title="enter PIN 6"
      type="text" name="auth-key" class="pin6 pin" min="0" max="9">

    <button title="verify pin" type="submit" [disabled]="pinForm.invalid" class="app-btn btn">Verify</button>

    <!-- <a class="link">Having trouble getting the code?</a> -->
  </form>
</main>