import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, lastValueFrom, takeUntil } from 'rxjs';
import { SessionData } from 'src/environments/environment';
import { VerifyPinDto } from 'src/app/dto/verify-pin.dto';
import { BackEndDataService } from 'src/app/services/backend-data.service';
import { LoaderService } from 'src/app/services/loader.service';
import { AlertService } from 'src/app/services/alert/alert.service';

@Component({
  selector: 'app-two-factor-auth',
  templateUrl: './two-factor-auth.component.html',
  styleUrls: ['./two-factor-auth.component.scss']
})
export class TwoFactorAuthComponent implements OnInit, OnDestroy {

  #destroy$: Subject<void> = new Subject<void>();

  date: string = ''

  pinForm: FormGroup = new FormGroup({
    pin1: new FormControl<string>(null, Validators.required),
    pin2: new FormControl<string>(null, Validators.required),
    pin3: new FormControl<string>(null, Validators.required),
    pin4: new FormControl<string>(null, Validators.required),
    pin5: new FormControl<string>(null, Validators.required),
    pin6: new FormControl<string>(null, Validators.required),
  })

  constructor(
    private readonly activeRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly backEndDataSvc: BackEndDataService,
    private readonly loaderSvc: LoaderService,
    private readonly alertSvc: AlertService,
  ) {
    this.loaderSvc.show();
  }

  ngOnInit(): void {
    this.#setParamsValues();
  }


  #setParamsValues() {
    this.activeRoute.queryParams.pipe(
      takeUntil(this.#destroy$)
    ).subscribe({
      next: (params) => {

        try {
          const token = params['a'];
          const formDigest = params['f'];
          const dataEncoded = params['data'];
          if (!(token && formDigest && dataEncoded)) {
            this.alertSvc.show('Payload missing');
            this.loaderSvc.hide();
            return;
          }
          const dataDecode: string = atob(dataEncoded);
          const dataArr = dataDecode.split(';');
          const clientId = dataArr[0].split('=')[1];
          const visitDate = dataArr[1].split('=')[1];
          const ncList = dataArr[2].split('=')[1];
          SessionData.token = encodeURIComponent(token);
          SessionData.formDigest = encodeURIComponent(formDigest);
          SessionData.clientId = clientId;
          SessionData.date = visitDate;
          this.date = SessionData.date;
          SessionData.ncList = ncList;
          this.loaderSvc.hide();
        }
        catch (err) {
          this.alertSvc.show('Invalid data')
          this.loaderSvc.hide();
        }
      },
      error: (err) => {
        this.loaderSvc.hide();
        this.alertSvc.show('Something went wrong')
      }
    })
  }

  onInput(e: InputEvent, nextInput?: HTMLInputElement, prevInput?: HTMLInputElement) {
    const value = (e.target as HTMLInputElement).value;
    if (value) {
      if (nextInput) {
        nextInput.focus()
      }
    } else {
      if (prevInput) {
        prevInput.focus();
      }
    }

  }

  async onSubmit() {
    if (this.pinForm.valid) {
      this.loaderSvc.show();
      const formValue = this.pinForm.value;
      const pin = `${formValue.pin1}${formValue.pin2}${formValue.pin3}${formValue.pin4}${formValue.pin5}${formValue.pin6}`;
      SessionData.pin = pin;

      const payload: VerifyPinDto = {
        fdv: SessionData.formDigest,
        token: SessionData.token,
        vc: pin
      }

      try {
        await lastValueFrom(this.backEndDataSvc.verifyPin(payload))
        sessionStorage.setItem('token-validated', 'Y')
        await this.router.navigateByUrl('upload-evidence')
      } catch (err) {
        this.alertSvc.show(err.message)
      } finally {
        this.loaderSvc.hide();
      }

    }
  }

  ngOnDestroy(): void {
    this.#destroy$.next();
    this.#destroy$.complete();
  }

} 
