import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog'
import { Component, Inject } from '@angular/core'
import { ConfirmBoxResult } from '../services/alert/alert.service'

@Component({
    template: `
        <section class="alert-wrapper">
            <h3 class="alert-title">{{data.title}}</h3>
            <p class="msg">{{data.message}}</p>
            <footer>
                <button *ngIf="data.neutralBtnText" (click)="dialogRef.close(buttonValues.Cancel)" type="button" title="Cancel and close dialog" class="app-btn">{{data.neutralBtnText}}</button>
                <span class="separator"></span>
                <button (click)="dialogRef.close(buttonValues.No)" type="button" title="Deny and close dialog" class="app-btn">{{data.cancelBtnText}}</button>
                <button (click)="dialogRef.close(buttonValues.Yes)" type="button" title="confirm and close dialog" class="app-btn">{{data.okBtnText}}</button>
            </footer>
        </section>
    `,
    styles: [
        `.alert-wrapper { 
            background-color: #fff;
            padding: 20px;
            border-radius: 5px;
            display: flex;
            flex-direction: column; 
        }`,
        `
        h3.alert-title {
            margin: 0;
            font-size : large;
        }
        `,
        `
         p.msg { font-size : medium; }   
        `,
        `
        span.separator {
            flex : auto;
        }
        `

        ,
        `
            footer {
                display: flex;
                gap : 10px;
                button { width : auto; padding : 0px 20px}
            }
        `

    ]
})
export class ConfirmComponent {

    buttonValues = ConfirmBoxResult;
    

    constructor(public dialogRef: DialogRef<string>, @Inject(DIALOG_DATA) public data: { title: string, message: string, okBtnText: string, cancelBtnText: string, neutralBtnText: string }) { }
}   