import { Dialog } from "@angular/cdk/dialog";
import { Injectable } from "@angular/core";
import { take } from "rxjs";
import { AlertComponent } from "src/app/components/alert.component";
import { ConfirmComponent } from "src/app/components/confirm.component";


export enum ConfirmBoxResult { Yes = 'Yes', No = 'No', Cancel = 'Cancel' }

@Injectable()
export class AlertService {
    constructor(
        private dialog: Dialog
    ) {

    }


    show(message: string, title?: string, okBtnText?: string, msgList?: string[]): Promise<void> {
        return new Promise<void>((res) => {

            const dialogRef = this.dialog.open(AlertComponent, {
                width: '250px',
                disableClose: true,
                data: {
                    title: title || 'Alert',
                    message,
                    okBtnText: okBtnText || 'Ok',
                    msgList
                }
            });
            dialogRef.closed.pipe(take(1)).subscribe(() => {
                res();
            })
        })
    }

    confirm(title: string, message: string, okBtnText: string = ConfirmBoxResult.Yes, cancelBtnText: string = ConfirmBoxResult.No, neutralBtnText?: typeof ConfirmBoxResult.Cancel): Promise<ConfirmBoxResult> {
        return new Promise((res, rej) => {

            const dialogRef = this.dialog.open(ConfirmComponent, {
                width: '400px',
                disableClose: true,
                data: {
                    title,
                    message,
                    okBtnText,
                    cancelBtnText,
                    neutralBtnText: neutralBtnText
                }
            });
            dialogRef.closed.pipe(take(1)).subscribe((data: ConfirmBoxResult) => {
                res(data);
            })

        })
    }

}