import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, map, type Observable } from "rxjs";
import { BASE_URL } from "src/environments/environment";
import { AppError } from "../error/app-error";

@Injectable()
export class HttpService {

    constructor(
        private readonly httpClient: HttpClient
    ) {

    }


    post<T>(options: {
        endPoint: string,
        body: any;
    }): Observable<T> {
        const url = BASE_URL + options.endPoint;
        return this.httpClient.post<T>(url, options.body).pipe(
            map((response: any) => {
                if (response.isSuccess && response.data) {
                    return response.data;
                }
                throw new AppError('Something went wrong', 'none')
            }), catchError((err) => {

                let errorMsg = 'Something went wrong';

                if (!navigator.onLine) {
                    errorMsg = 'Network error'
                } else {
                    if (err instanceof HttpErrorResponse) {
                        if (err.status === 401) {
                            errorMsg = 'Unauthorized';
                        } /* else if(err.status === 500) {
                            errorMsg = 'Internal server error';
                        } */
                    }
                }
                throw new AppError(errorMsg, 'none')
            })
        )
    }


    #handleError() {

    }
}