import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { LoaderService } from './services/loader.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {


  @ViewChild('loader', { static: true }) loaderRef: ElementRef<HTMLDialogElement>;

  constructor(
    private readonly loaderSvc: LoaderService
  ) {
  }

  ngOnInit(): void {
    this.loaderSvc.loaderState$.subscribe(state => {
      if (state.visibility) {
        this.loaderRef.nativeElement.show()
      } else {
        this.loaderRef.nativeElement.close();
      }
    })
  }


}
