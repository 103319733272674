import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog'
import { Component, Inject } from '@angular/core'

@Component({
    template: `
        <section class="alert-wrapper">
            <h3 class="alert-title">{{data.title}}</h3>
            <p class="msg">{{data.message}}</p>
            <ul class="msg-list" *ngIf="data.msgList?.length">
                <li *ngFor="let msg of data.msgList">{{msg}}</li>
            </ul>
            <footer>
                <button (click)="dialogRef.close()" type="button" title="Close dialog" class="app-btn">{{data.okBtnText}}</button>
            </footer>
        </section>
    `,
    styles: [
        `.alert-wrapper { 
            background-color: #fff;
            padding: 20px;
            border-radius: 5px;
            display: flex;
            flex-direction: column; 
        }`,
        `
        h3.alert-title {
            margin: 0;
            font-size : large;
        }
        `,
        `
        ul.msg-list {
            padding: 0 0 0 15px;
            margin: 0 0 9px 0;
            max-height : 150px;
            overflow : auto;
            li {
                overflow-wrap: break-word;
                font-size : 14px
            }
        }
        `,

        `
         p.msg { font-size : medium; }   
        `
        ,
        `
            footer {
                display: flex;
                justify-content: flex-end;
                button { width : auto; padding : 0px 20px}
            }
        `

    ]
})
export class AlertComponent {

    constructor(public dialogRef: DialogRef<string>, @Inject(DIALOG_DATA) public data: { title: string, message: string, okBtnText: string, msgList ?: string[] }) { }
}   